/* Add the following styles to your existing stylesheet or create a new one */

/* Style for the overall container */
.contact-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
  }
  
  /* Style for the form */
  .contact-form {
    display: grid;
    gap: 10px;
  }
  
  /* Style for labels */
  .contact-form label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  /* Style for input and textarea containers */
  .contact-form div {
    display: grid;
    gap: 5px;
  }
  
  /* Style for text input and textarea */
  .contact-form input,
  .contact-form textarea {
    width: calc(100% - 10px); /* Adjust width to leave some space on the right */
    padding: 8px;
    box-sizing: border-box;
  }
  
  /* Style for error messages */
  .error {
    color: red;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  
  /* Style for the submit button */
  .contact-form button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #45a049;
  }
  .or-text {
    font-weight: bold;
    display: block;
    text-align: center;
    /* Add any additional styling as needed */
  }
  .Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* Add any additional styling as needed */
  }