.home-container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* Align text to the center */
  justify-content:left; /* Align text to the top center */
}

.animated-item-bottom{
  opacity: 0;
  transform: translateY(420px); /* Start position for flyInBottom animation */
}
.animated-item-left {
  opacity: 0;
  transform: translateX(-420px); /* Start position for flyInBottom animation */
}

.fly-in-left {
  animation: flyInLeft 1s ease forwards;
}

.fly-in-bottom {
  animation: flyInBottom 1s ease forwards;
}

h1, h2, h3, h4, h5 {
  font-family: 'Times New Roman', Times, serif;
  text-align: justify; /* Adjust text alignment based on your preference */
}

@keyframes flyInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes flyInBottom {
  to {
    opacity: 1;
    transform: translateY(0);
  }


}
